import PrimeVue from 'primevue/config';
import { defaultOptions } from 'primevue/config';
import pt from '@/assets/styles/pt';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, {
    unstyled: true,
    locale: {
      ...defaultOptions.locale,
      firstDayOfWeek: 1,
    },
    pt,
  });
  nuxtApp.vueApp.use(DialogService);
  nuxtApp.vueApp.use(ToastService);
});
