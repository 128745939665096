import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45msw_45global from "/opt/buildhome/repo/middleware/01.msw.global.ts";
import authorized_45only_45global from "/opt/buildhome/repo/middleware/authorizedOnly.global.ts";
import root_45global from "/opt/buildhome/repo/middleware/root.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45msw_45global,
  authorized_45only_45global,
  root_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}