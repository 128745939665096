import { type ButtonProps } from 'primevue/button';
import { type DropdownProps } from 'primevue/dropdown';
import { CheckboxContext } from 'primevue/checkbox';
import { InputSwitchContext } from 'primevue/inputswitch';
import type { ToastProps, ToastState } from 'primevue/toast';

const dropdown = {
  root: ({ props }: { props: DropdownProps }) => ({
    class: ['Dropdown__Root', { Dropdown__Disabled: props.disabled }],
  }),
  header: 'Dropdown__Header',
  filtericon: 'Dropdown__FilterIcon',
  filtercontainer: 'Dropdown__FilterContainer',
  filterinput: 'Dropdown__FilterInput',
  panel: 'Dropdown__Panel',
  emptymessage: 'Dropdown__EmptyMessage',
  trigger: 'Dropdown__Trigger',
  dropdownicon: 'Dropdown__DropdownIcon',
  item: 'Dropdown__Item Dropdown__Item_Hoverable',
};

export default {
  chip: { root: 'Chip', removeicon: 'Chip__RemoveIcon' },
  inputtext: { root: 'Input' },
  autocomplete: { input: 'Input', ...dropdown, root: '' },
  inputnumber: { input: 'Input' },
  inputswitch: {
    input: 'InputSwitch',
    root: ({ context }: { context: InputSwitchContext }) => ({
      class: [
        'InputSwitch__Root',
        { InputSwitch__Root_Active: context.checked },
      ],
    }),
    slider: ({ context }: { context: InputSwitchContext }) => ({
      class: [
        'InputSwitch__Slider',
        { InputSwitch__Slider_Active: context.checked },
      ],
    }),
  },
  password: {
    root: 'Password__Root',
    input: 'Password__Input',
  },
  selectbutton: {
    root: 'SelectButton__Root',
    button: 'SelectButton__Button',
  },
  button: {
    root: ({ props }: { props: ButtonProps }) => ({
      class: [
        'Button',
        {
          Button_Theme_PrimaryFilled:
            props.severity === null && !props.outlined,
          Button_Theme_PrimaryOutline:
            props.severity === null && props.outlined === true,
          Button_Theme_Secondary: props.severity === 'secondary',
        },
      ],
    }),
  },
  multiselect: {
    ...dropdown,
    header: 'MultiSelect__Header',
    closebutton: 'MultiSelect__CloseButton',
    label: 'MultiSelect__Label',
    dropdownicon: 'MultiSelect__DropdownIcon',
    footer: 'Dropdown__Header',
    token: 'MultiSelect__Token',
    closeButton: 'MultiSselect__CloseButton',
    removeTokenIcon: 'MultiSelect__RemoveTokenIcon',
  },
  dropdown: {
    ...dropdown,
    label: 'text-grayscale-400',
  },
  textarea: { root: 'Textarea' },
  calendar: {
    ...dropdown,
    root: 'Calendar__Root group',
    input: 'Calendar__Input',
    panel: 'Calendar__Panel',
    day: 'Calendar__Day',
    dayLabel: 'Calendar__DayLabel',
    weekDay: 'Clendar__WeekDay',
    table: 'Calendar__Table',
    title: 'Calendar__Title',
    header: 'Calendar__Header',
    navigator: 'Calendar__Navigator',
    // dropdownButton: {
    //   root: 'DropdownButton',
    //   label: 'DropdownButton__Label',
    // },
    timePicker: 'Calendar__TimePicker',
    hourPicker: 'Calendar__HourPicker',
    minutePicker: 'Calendar__HourPicker',
    incrementButton: 'Calendar__IncrementButton',
    decrementButton: 'Calendar__IncrementButton',
    buttonBar: 'Calendar__ButtonBar',
  },
  dialog: {
    root: 'Dialog__Root',
    content: 'Dialog__Content',
    mask: 'Dialog__Mask',
    closeButton: 'Dialog__CloseButton',
  },
  radiobutton: {
    input: 'RadioButton__Input',
    box: ({ context }: { context: CheckboxContext }) => ({
      class: [
        'RadioButton__Box',
        {
          RadioButton__Box_Checked_True: context.checked,
          RadioButton__Box_Checked_False: !context.checked,
        },
      ],
    }),
    icon: 'hidden',
  },
  checkbox: {
    input: 'Checkbox__Input',
    box: ({ context }: { context: CheckboxContext }) => ({
      class: [
        'Checkbox__Box',
        {
          Checkbox__Box_Checked_True: context.checked,
          Checkbox__Box_Checked_False: !context.checked,
        },
      ],
    }),
    icon: 'hidden',
  },
  paginator: {
    paginatorWrapper: 'Paginator__PaginatorWrapper',
    root: 'Paginator__Root',
    pages: 'Paginator__Pages',
    previousPageButton: 'Paginator__Button',
    nextPageButton: 'Paginator__Button',
    firstPageButton: 'Paginator__Button',
    lastPageButton: 'Paginator__Button',
    pageButton: 'Paginator__Button',
  },
  inputotp: { root: 'InputOtp__Root', input: { root: 'InputOtp__Input' } },
  progressbar: {
    root: 'ProgressBar',
    value: 'ProgressBar__Value',
    label: 'ProgressBar__Label',
  },
  progressspinner: {
    root: 'ProgressSpinner__Root',
    spinner: 'ProgressSpinner',
    circle: 'ProgressSpinner__Circle',
  },
  toast: {
    root: ({ state }: { state: ToastState }) => {
      return {
        class: [
          'Toast',
          {
            Toast_Hidden: state.messages.length === 0,
          },
        ],
      };
    },
    message: 'Toast__Message',
    content: ({ props }: { props: ToastProps }) => ({
      class: [
        'Toast__Content',
        {
          Toast__Content_Info:
            props.message && props.message.severity === 'info',
          Toast__Content_Success:
            props.message && props.message.severity === 'success',
          Toast__Content_Warning:
            props.message && props.message.severity === 'warn',
          Toast__Content_Error:
            props.message && props.message.severity === 'error',
        },
      ],
    }),
    summary: 'Toast__Summary',
    icon: 'Toast__Icon',
  },
};
