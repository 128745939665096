import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as duplicatee51NE8QSRYMeta } from "/opt/buildhome/repo/pages/invoices/[type]/[invoiceId]/[orderId]/duplicate.vue?macro=true";
import { default as indexvrtc55dMYuMeta } from "/opt/buildhome/repo/pages/invoices/[type]/[invoiceId]/[orderId]/index.vue?macro=true";
import { default as purchase_45invoiceaf1S9dTcoCMeta } from "/opt/buildhome/repo/pages/invoices/new/purchase-invoice.vue?macro=true";
import { default as sale_45invoiceCAitWOk6VgMeta } from "/opt/buildhome/repo/pages/invoices/new/sale-invoice.vue?macro=true";
import { default as invoicesckbzBQNLt5Meta } from "/opt/buildhome/repo/pages/invoices.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as edit6EMfZkmZB9Meta } from "/opt/buildhome/repo/pages/orders/[orderId]/edit.vue?macro=true";
import { default as _91documentId_93L8GVBSkTc3Meta } from "/opt/buildhome/repo/pages/orders/[orderId]/index/document/[documentId].vue?macro=true";
import { default as indexONtkDal2pjMeta } from "/opt/buildhome/repo/pages/orders/[orderId]/index.vue?macro=true";
import { default as _91orderId_935DI3UWSJJoMeta } from "/opt/buildhome/repo/pages/orders/[orderId].vue?macro=true";
import { default as index2v2eoKuFvxMeta } from "/opt/buildhome/repo/pages/orders/new/index.vue?macro=true";
import { default as orderspVyfxG93eVMeta } from "/opt/buildhome/repo/pages/orders.vue?macro=true";
import { default as editDhZdQRjTDoMeta } from "/opt/buildhome/repo/pages/organizations/[organizationId]/edit.vue?macro=true";
import { default as indexIqe3QXFar4Meta } from "/opt/buildhome/repo/pages/organizations/[organizationId]/index.vue?macro=true";
import { default as indexGx8UKp9kGoMeta } from "/opt/buildhome/repo/pages/organizations/new/index.vue?macro=true";
import { default as index9FPt2E8cJCMeta } from "/opt/buildhome/repo/pages/preview-pdf/[orderId]/[type]-invoices/[invoiceId]/index.vue?macro=true";
import { default as profileg2OzvUzQjKMeta } from "/opt/buildhome/repo/pages/profile.vue?macro=true";
import { default as toolsR4YN1MOTeTMeta } from "/opt/buildhome/repo/pages/tools.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: invoicesckbzBQNLt5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/invoices.vue"),
    children: [
  {
    name: "invoices-type-invoiceId-orderId-duplicate",
    path: ":type()/:invoiceId()/:orderId()/duplicate",
    component: () => import("/opt/buildhome/repo/pages/invoices/[type]/[invoiceId]/[orderId]/duplicate.vue")
  },
  {
    name: "invoices-type-invoiceId-orderId",
    path: ":type()/:invoiceId()/:orderId()",
    component: () => import("/opt/buildhome/repo/pages/invoices/[type]/[invoiceId]/[orderId]/index.vue")
  },
  {
    name: "invoices-new-purchase-invoice",
    path: "new/purchase-invoice",
    component: () => import("/opt/buildhome/repo/pages/invoices/new/purchase-invoice.vue")
  },
  {
    name: "invoices-new-sale-invoice",
    path: "new/sale-invoice",
    component: () => import("/opt/buildhome/repo/pages/invoices/new/sale-invoice.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: orderspVyfxG93eVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders.vue"),
    children: [
  {
    name: _91orderId_935DI3UWSJJoMeta?.name,
    path: ":orderId()",
    component: () => import("/opt/buildhome/repo/pages/orders/[orderId].vue"),
    children: [
  {
    name: "orders-orderId-edit",
    path: "edit",
    component: () => import("/opt/buildhome/repo/pages/orders/[orderId]/edit.vue")
  },
  {
    name: "orders-orderId",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/orders/[orderId]/index.vue"),
    children: [
  {
    name: "orders-orderId-index-document-documentId",
    path: "document/:documentId()",
    component: () => import("/opt/buildhome/repo/pages/orders/[orderId]/index/document/[documentId].vue")
  }
]
  }
]
  },
  {
    name: "orders-new",
    path: "new",
    component: () => import("/opt/buildhome/repo/pages/orders/new/index.vue")
  }
]
  },
  {
    name: "organizations-organizationId-edit",
    path: "/organizations/:organizationId()/edit",
    component: () => import("/opt/buildhome/repo/pages/organizations/[organizationId]/edit.vue")
  },
  {
    name: "organizations-organizationId",
    path: "/organizations/:organizationId()",
    component: () => import("/opt/buildhome/repo/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-new",
    path: "/organizations/new",
    component: () => import("/opt/buildhome/repo/pages/organizations/new/index.vue")
  },
  {
    name: "preview-pdf-orderId-type-invoices-invoiceId",
    path: "/preview-pdf/:orderId()/:type()-invoices/:invoiceId()",
    meta: index9FPt2E8cJCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preview-pdf/[orderId]/[type]-invoices/[invoiceId]/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/buildhome/repo/pages/profile.vue")
  },
  {
    name: "tools",
    path: "/tools",
    component: () => import("/opt/buildhome/repo/pages/tools.vue")
  }
]