import revive_payload_client_JJ9uWBC7iE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dPtj7yHPTw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_y4kg6fz2PQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vDQnwjorqz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Tu4H1pCHZb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FTko6OMA8X from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mdGxDlm1N3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_z5F5aAETBe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_fWHSW13Gt0 from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_6mni4fs2miheq54sfjxwm4ls6q/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_6Ph1iQzNHk from "/opt/buildhome/repo/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import primevue_7rYYRZQLyx from "/opt/buildhome/repo/plugins/primevue.ts";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_JJ9uWBC7iE,
  unhead_dPtj7yHPTw,
  router_y4kg6fz2PQ,
  payload_client_vDQnwjorqz,
  navigation_repaint_client_Tu4H1pCHZb,
  check_outdated_build_client_FTko6OMA8X,
  chunk_reload_client_mdGxDlm1N3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_z5F5aAETBe,
  sentry_client_fWHSW13Gt0,
  sentry_client_config_o34nk2sJbg,
  plugin_6Ph1iQzNHk,
  primevue_7rYYRZQLyx,
  vue_query_wrmMkNpEpe
]