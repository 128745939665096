/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Charge,
  ChargeCreateRequest,
  PatchedChargeCreateRequest,
  SecCheck,
  SecCheckCreateRequest,
  TransactionsChargesCreateCodeEnum,
  TransactionsChargesCreateTypeEnum,
  TransactionsChargesUpdateCodeEnum,
  TransactionsChargesUpdateTypeEnum,
  TransactionsSecCheckCreateCodeEnum,
  TransactionsSecCheckCreateTypeEnum,
  TransactionsSecCheckDestroyCodeEnum,
  TransactionsSecCheckDestroyTypeEnum,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Transactions<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Return detail-serialized created instance
   *
   * @tags transactions
   * @name TransactionsChargesList
   * @request GET:/api/v1/transactions/{transactionPk}/charges/
   * @secure
   * @response `200` `(Charge)[]`
   */
  transactionsChargesList = (
    transactionPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<Charge[], any>({
      path: `/api/v1/transactions/${transactionPk}/charges/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags transactions
 * @name TransactionsChargesCreate
 * @request POST:/api/v1/transactions/{transactionPk}/charges/
 * @secure
 * @response `201` `Charge`
 * @response `400` `{
    type: TransactionsChargesCreateTypeEnum,
    error: {
    code: TransactionsChargesCreateCodeEnum,
    message: string,

},

}`
 */
  transactionsChargesCreate = (
    transactionPk: string,
    data: ChargeCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      Charge,
      {
        type: TransactionsChargesCreateTypeEnum;
        error: {
          code: TransactionsChargesCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/transactions/${transactionPk}/charges/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags transactions
   * @name TransactionsChargesRetrieve
   * @request GET:/api/v1/transactions/{transactionPk}/charges/{id}/
   * @secure
   * @response `200` `Charge`
   */
  transactionsChargesRetrieve = (
    id: string,
    transactionPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<Charge, any>({
      path: `/api/v1/transactions/${transactionPk}/charges/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags transactions
 * @name TransactionsChargesUpdate
 * @request PUT:/api/v1/transactions/{transactionPk}/charges/{id}/
 * @secure
 * @response `200` `Charge`
 * @response `400` `{
    type: TransactionsChargesUpdateTypeEnum,
    error: {
    code: TransactionsChargesUpdateCodeEnum,
    message: string,

},

}`
 */
  transactionsChargesUpdate = (
    id: string,
    transactionPk: string,
    data: ChargeCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      Charge,
      {
        type: TransactionsChargesUpdateTypeEnum;
        error: {
          code: TransactionsChargesUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/transactions/${transactionPk}/charges/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags transactions
   * @name TransactionsChargesPartialUpdate
   * @request PATCH:/api/v1/transactions/{transactionPk}/charges/{id}/
   * @secure
   * @response `200` `Charge`
   */
  transactionsChargesPartialUpdate = (
    id: string,
    transactionPk: string,
    data: PatchedChargeCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<Charge, any>({
      path: `/api/v1/transactions/${transactionPk}/charges/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags transactions
   * @name TransactionsChargesDestroy
   * @request DELETE:/api/v1/transactions/{transactionPk}/charges/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  transactionsChargesDestroy = (
    id: string,
    transactionPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/transactions/${transactionPk}/charges/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name TransactionsSecCheckRetrieve
   * @request GET:/api/v1/transactions/{transactionPk}/sec-check/
   * @secure
   * @response `200` `SecCheck`
   */
  transactionsSecCheckRetrieve = (
    transactionPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<SecCheck, any>({
      path: `/api/v1/transactions/${transactionPk}/sec-check/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Perform SecCheck for the transaction's invoice. If SecCheck already exists, it will be replaced.
 *
 * @tags transactions
 * @name TransactionsSecCheckCreate
 * @request POST:/api/v1/transactions/{transactionPk}/sec-check/
 * @secure
 * @response `200` `SecCheck`
 * @response `400` `{
    type: TransactionsSecCheckCreateTypeEnum,
    error: {
    code: TransactionsSecCheckCreateCodeEnum,
    message: string,

},

}`
 */
  transactionsSecCheckCreate = (
    transactionPk: string,
    data: SecCheckCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SecCheck,
      {
        type: TransactionsSecCheckCreateTypeEnum;
        error: {
          code: TransactionsSecCheckCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/transactions/${transactionPk}/sec-check/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * No description
 *
 * @tags transactions
 * @name TransactionsSecCheckDestroy
 * @request DELETE:/api/v1/transactions/{transactionPk}/sec-check/
 * @secure
 * @response `204` `void` No response body
 * @response `400` `{
    type: TransactionsSecCheckDestroyTypeEnum,
    error: {
    code: TransactionsSecCheckDestroyCodeEnum,
    message: string,

},

}`
 */
  transactionsSecCheckDestroy = (
    transactionPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<
      void,
      {
        type: TransactionsSecCheckDestroyTypeEnum;
        error: {
          code: TransactionsSecCheckDestroyCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/transactions/${transactionPk}/sec-check/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
