import { useRouteQuery } from '@vueuse/router';
import { InvoicesListParams } from '~/api/data-contracts';
import dayjs from '~/utils/dayjs';
import { BACKEND_DATE_FORMAT, toBackendDate } from '~/utils/toBackendDate';

export const PAGE_SIZE_INVOICES = 100;

export const useInvoicesFilters = () => {
  const page = useRouteQuery('page', '1', {
    transform: Number,
  });
  const search = useRouteQuery('search', '');

  const invoiceType = useRouteQuery<string[]>('invoiceType', undefined);

  const counterparty = useRouteQuery<string[]>('counterparty', [], {
    transform(val) {
      return Array.isArray(val) ? val : [val];
    },
  });

  const serviceProvider = useRouteQuery<string | undefined>(
    'serviceProvider',
    undefined,
  );

  const status = useRouteQuery('status', undefined);

  const transportationMethods = useRouteQuery<string[]>(
    'transportationMethods',
    [],
  );

  const invoicingDateAfter = useRouteQuery<string | null>(
    'invoicingDateAfter',
    null,
  );
  const invoicingDateBefore = useRouteQuery<string | null>(
    'invoicingDateBefore',
    null,
  );

  const calendar = computed<[Date, Date | null] | undefined>({
    get() {
      const value: Date[] = [];

      if (invoicingDateAfter.value) {
        value[0] = dayjs(
          invoicingDateAfter.value,
          BACKEND_DATE_FORMAT,
        ).toDate();
      }
      if (invoicingDateBefore.value) {
        value[1] = dayjs(
          invoicingDateBefore.value,
          BACKEND_DATE_FORMAT,
        ).toDate();
      }

      if (value.length === 0) return undefined;
      return value as [Date, Date | null];
    },
    set(newValue) {
      if (newValue && newValue[0]) {
        invoicingDateAfter.value = toBackendDate(newValue[0]);
      } else {
        invoicingDateAfter.value = null;
      }
      if (newValue && newValue[1]) {
        invoicingDateBefore.value = toBackendDate(newValue[1]);
      } else {
        invoicingDateBefore.value = null;
      }
    },
  });

  const filters = computed<InvoicesListParams>(() => {
    return {
      page: page.value,
      search: search.value,
      invoiceType: invoiceType.value ? invoiceType.value[0] : '',
      status: status.value,
      serviceProvider: serviceProvider.value,
      counterparty: counterparty.value,
      transportationMethods: transportationMethods.value,
      invoicingDateAfter: invoicingDateAfter.value ?? undefined,
      invoicingDateBefore: invoicingDateBefore.value ?? undefined,
      page_size: PAGE_SIZE_INVOICES,
    };
  });

  return {
    calendar,
    invoicingDateAfter,
    invoicingDateBefore,
    page,
    search,
    transportationMethods,
    invoiceType,
    status,
    serviceProvider,
    counterparty,
    filters,
  };
};
