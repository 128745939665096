import { Orders } from '~/api/Orders';
import { Departments } from '~/api/Departments';
import { Users } from '~/api/Users';
import { Auth } from '~/api/Auth';
import { Clients } from '~/api/Clients';
import { OrderLabels } from '~/api/OrderLabels';
import { Agents } from '~/api/Agents';
import { OrderDocumentTags } from '~/api/OrderDocumentTags';
import { ToolsLinkGroups } from '~/api/ToolsLinkGroups';
import { Tasks } from '~/api/Tasks';
import { CurrencyRates } from '~/api/CurrencyRates';
import { Services } from '~/api/Services';
import { Transactions } from '~/api/Transactions';
import { Invoices } from '~/api/Invoices';
import { InvoicesTotalNet } from '~/api/InvoicesTotalNet';
import { InvoicesExport } from '~/api/InvoicesExport';
import { InvoicesDocumentArchiveExport } from '~/api/InvoicesDocumentArchiveExport';
import { Organizations } from '~/api/Organizations';
import { OrdersTotalNet } from '~/api/OrdersTotalNet';
import { OrdersTotalNetExport } from '~/api/OrdersTotalNetExport';
import { Definitions } from '~/api/Definitions';
import { Notifications } from '~/api/Notifications';

export const ordersApi = new Orders(http);
export const orderDocumentsApi = new OrderDocumentTags(http);
export const departmentsApi = new Departments(http);
export const usersApi = new Users(http);
export const authApi = new Auth(http);
export const clientsApi = new Clients(http);
export const orderLabelsApi = new OrderLabels(http);
export const agentsApi = new Agents(http);
export const toolsApi = new ToolsLinkGroups(http);
export const tasksApi = new Tasks(http);
export const currencyRatesApi = new CurrencyRates(http);
export const servicesApi = new Services(http);
export const transactionsApi = new Transactions(http);
export const invoicesApi = new Invoices(http);
export const invoicesTotalNetApi = new InvoicesTotalNet(http);
export const ordersTotalNetApi = new OrdersTotalNet(http);
export const invoicesExportApi = new InvoicesExport(http);
export const invoicesDocumentArchiveExportApi =
  new InvoicesDocumentArchiveExport(http);
export const organizationsApi = new Organizations(http);
export const ordersTotalNetExportApi = new OrdersTotalNetExport(http);
export const definitionsApi = new Definitions(http);
export const notificationsApi = new Notifications(http);
